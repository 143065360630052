import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image = require("../../../assets/img/blogs/roi_blog_header.png");
const section_1 = require("../../../assets/img/blogs/roi_blog_b1.png");
const section_2 = require("../../../assets/img/blogs/roi_blog_b2.png");
const section_3 = require("../../../assets/img/blogs/roi_blog_b3.png");
const section_4 = require("../../../assets/img/blogs/roi_blog_b4.png");
const section_5 = require("../../../assets/img/blogs/roi_blog_b5.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="The ROI of Using Chatbot and Automation in Your IT Service Desk"
        description="Maximize your IT service desk operations with chatbot and automation. Learn how to leverage these innovative solutions to increase efficiency and reduce costs."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt roi_ch_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                <h1
                  className={` color-white ${
                    isMobile
                      ? "font-section-sub-header-blog-title"
                      : "font-section-sub-header-blog-title"
                  }`}
                >
                  The ROI of Using Chatbot <br /> and Automation with Your{" "}
                  <br />
                  IT Service Desk​
                </h1>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Four factors to consider that impacting your operational
                  costs or budget
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Impact of recession on CFOs
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Get a competitive advantage from Workativ Automation
                  Platforms
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Real-World Case Studies
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                As organizations continue to digitize their operations and
                embrace new technologies, the role of the CIO is evolving.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                One key aspect of the CIO’s role in digital transformation is to
                serve as a bridge between the IT department and the rest of the
                organization.​
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that said, CIOs also need to address challenges aggravated
                by the Covid-19 pandemic and the ongoing recession and
                inflation, so CFOs and companies can combat pressure by reducing
                operating costs and maintaining profitability.​
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                ​As a CFO of your business, you are likely implementing
                cost-take-out (CTO) actions on IT spending, and the IT service
                desk could be an easy target for companies to reduce their IT
                spending, but you need to ensure that it doesn’t affect the IT
                Service desk’s efficiency and performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To ensure IT service resiliency as well as high performance, you
                can follow a few steps to stay ahead of IT help desk risks and
                prepare for real-time operational restoration.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                {" "}
                Evaluate certain aspects of IT service desk expenses, which you
                can reduce with an effective plan.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Four factors to consider that impacting your operational costs
                or budget
              </h2>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Impact of recession on CFOs
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Cost of the problem​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ROI of chatbot and automation​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Traditional VS AI–Powered Service desk
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Impact of recession on CFOs
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Economic Factors
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  IT Wages have shot up
                </li>
                <li className="font-section-normal-text-testimonials">
                  Cost of non-IT spending has gone up (Licenses, real estate,
                  inflation, etc.)
                </li>
                <li className="font-section-normal-text-testimonials">
                  A major downturn in the economy – predicts recession in US/EU
                  ( cost of capital has gone up)​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Cost-take-out Actions underway by CTOs/CIOs and CFOs​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Hybrid Workplace model – Tech + Machines ​
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Cost of the problem - Putting a $ value
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="cost of traditional service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                So, as we went through the problem and challenges, let’s also
                try to estimate the cost of the problem. For example, what’s
                costing you for not transforming to an AI-first service desk
                model?
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this case, we assume that you are a company with 3000
                employees, and roughly creating about 5000 tickets per month,
                having about 20 agents at various levels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We assume that 60% of the total ticket volume is handled at the
                L1 level, so these are simple to semi-complex issues that an L1
                staff can resolve. And rest of the tickets, due to its
                complexity, are passed on to L2 and L3 folks.
              </p>
              <BlogCta
                ContentCta="Reduce 40% Your IT Service Desk Operations Cost with Automation."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                So, coming back to L1, let’s assume that 60% of L1 tickets are
                eligible for FCR, i.e., 1800 tickets are L1 eligible, but as you
                know, not all tickets can be closed on the first call, so let’s
                assume that, on average, 80% of eligible tickets are resolved,
                so about 1400 tickets are closed on the first call. And all this
                takes about anywhere from 20 mins to 4 hrs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But let’s focus on the cost of this exercise and assume that it
                costs $15 per ticket for the company.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So as you can see, simple maths tells us that the overall cost
                for addressing 3000 tickets is 45K, and of which those 1400
                tickets cost you 21K per month, so by not having an AI chatbot
                that automatically resolves those 1400 easily, you have spent
                21K. That is one way of reducing the cost.
              </p>
              <h3 className="font-section-sub-header-small-home">
                ROI Estimates​
              </h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="RoI of AI-Powered service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Now, let’s see what would happen if we introduce a chatbot to
                help us resolve FCR tickets. With a chatbot, the average handle
                time per ticket comes down to less than a minute, and the
                average cost per ticket reduces to $3. That means the total cost
                of resolving 1440 tickets comes down to $4,320, resulting in a
                5X cost saving of $17,280 monthly and $207360 annually.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Then you can save 3546 agent hours annually.
              </p>
              <NocodeWrapper />
              <h3 className="font-section-sub-header-small-home">
                What is the ROI for your chatbot or product?​
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Hard ROI is cost savings that can be visible as early as the
                first quarter. This also depends on your user adoption. There
                are a lot of soft ROI as well that can be quantified. ​
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Hard ROI or immediate cost
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​User productivity​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workflow or service request automation​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Employee experience
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​SLAs such as Mean time to resolution (MTTR), FCR, etc​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​​Employee and Agent productivity
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The ROI for your chatbot or product is easy to quantify, and
                there are some key areas that contribute to it.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Cost savings – on average, a chatbot can automate 40-50% (or
                  more) of ticket volumes, and this can result in hard cost
                  savings from day1.
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Improve MTTR – with issues being auto-resolved by the bot
                  instantly or in seconds, your mean time to resolution (MTTR)
                  goes down by 30-40% or more.​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Improve CSAT – with instant resolution using a chat apps like
                  MS Teams or slack and employees not calling or emailing the
                  helpdesk, this can boost employee CSAT by 60-70%​​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Productivity – with hours saved back and forth from calls and
                  emails, employee productivity goes up. On average, you are
                  saving 40 mins to hours per employee per month.​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee Experience – employees are used to using WhatsApp and
                  modern payment in their personal lives, and they expect the
                  same level of experience at the workplace as well.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/blog/it-support-automation-guide">
                    Scaling your IT support Service Desk -
                  </a>{" "}
                  in the traditional model, you will need to hire, train, and
                  operate more agents to scale your service desk, but with a
                  chatbot, your scaling efforts and costs are almost nil.​
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR – can automate your mundane HR enquires and free up HR
                  teams time and efforts to focus on more meaning full
                  activities. ​
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Traditional VS Modern Service Desk
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Traditional vs Modern service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                So, let’s now see how you can{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  leverage your existing service desk team
                </a>{" "}
                and yet
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  transform it into the AI-First Service Desk Model.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                First, leverage data to train a chatbot, so it can intelligently
                and contextually respond and address to employee queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Second, whilst agents are critical to the service desk,
                re-balancing the approach with chatbot + agents creates the
                right mix of skills with cost benefits
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Third, use Automation to improve service delivery SLAs. What
                took for an account to get unlocked in 30 mins now be unlocked
                in less than a minute using a chatbot.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Imagine how this could impact your SLAs.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Fourth, proactive measures to reduce reliance on calls to the
                service desk frequently finally, you can create a rich
                experience with personalization.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Get a competitive advantage from Workativ Automation Platforms
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Workativ"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                is focused on helping companies automate workplace support and
                tasks. Established in 2019, we have come a long way, helping
                companies from different industry in transform their workplace
                using Woraktiv Assistant and Workativ Automate (Which is due for
                a release in the next two weeks)
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, Workativ assistant helps companies of all sizes to build
                conversational AI chatbots with app automation in minutes
                quickly. This helps companies adopt the AI-first service desk
                approach. We have helped popular companies like GoTo,
                Congnisante, and IPONWEB leverage chatbots to modernize employee
                IT and HR support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs mb-3">
                Real-World Case Studies
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Client - GoTo
              </h3>

              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="GoTo case study"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Here is a quick overview into a{" "}
                <a href="https://workativ.com/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                  case study
                </a>{" "}
                of our client <a href="https://www.goto.com/">GoTo</a> 一 a
                renowned and one of the leading SaaS companies with more than
                3500 employees.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                GoTo needed an automated solution that would help them manage
                all the repetitive tasks such as installing software, resetting
                passwords, unlocking accounts, and distributing lists for
                employees with more than 50+ applications. 
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                They knew there was something better out there for them, so they
                reached out to Workativ for help (and 4 other chatbot vendors)
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                Workativ ensured that a chatbot would be made available at any
                given time when an employee needed help with their IT-related
                issues.  Workativ helped the GoTo team to auto-resolve
                repetitive IT queries, issues, and requests and improve their
                employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Amidst the economic crisis, it will be inviting to invest in
                workflow automation through chatbots or sophisticated automation
                models that help save big on operating costs. For CFOs who want
                to reduce the economic strain on the IT infrastructure, chatbots
                and automation platforms can help in fulfilling that ambition by
                accelerating IT support efficiency and productivity.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The picture is clear here,
              </p>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Automation is more widely a CIO’s top priority​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Automation is more mature and available via the cloud​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Customers are choosing automation at a small scale to gain
                  high impact​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Chatbots are becoming a high priority for customers and
                  employee support
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                It would definitely bring back values to your ROI should you
                consider automation as your next level high end IT initiates.
                Look back at all these gains that automation brings to your
                balance sheet and give it a nod.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />

            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Four factors to consider that impacting your operational
                    costs or budget
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Impact of recession on CFOs
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Get a competitive advantage from Workativ Automation
                    Platforms
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Real-World Case Studies
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                As organizations continue to digitize their operations and
                embrace new technologies, the role of the CIO is evolving.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                One key aspect of the CIO’s role in digital transformation is to
                serve as a bridge between the IT department and the rest of the
                organization.​
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that said, CIOs also need to address challenges aggravated
                by the Covid-19 pandemic and the ongoing recession and
                inflation, so CFOs and companies can combat pressure by reducing
                operating costs and maintaining profitability.​
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                ​As a CFO of your business, you are likely implementing
                cost-take-out (CTO) actions on IT spending, and the IT service
                desk could be an easy target for companies to reduce their IT
                spending, but you need to ensure that it doesn’t affect the IT
                Service desk’s efficiency and performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To ensure IT service resiliency as well as high performance, you
                can follow a few steps to stay ahead of IT help desk risks and
                prepare for real-time operational restoration.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                {" "}
                Evaluate certain aspects of IT service desk expenses, which you
                can reduce with an effective plan.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Four factors to consider that impacting your operational costs
                or budget
              </h2>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Impact of recession on CFOs
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Cost of the problem​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ROI of chatbot and automation​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Traditional VS AI–Powered Service desk
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Impact of recession on CFOs
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Economic Factors
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  IT Wages have shot up
                </li>
                <li className="font-section-normal-text-testimonials">
                  Cost of non-IT spending has gone up (Licenses, real estate,
                  inflation, etc.)
                </li>
                <li className="font-section-normal-text-testimonials">
                  A major downturn in the economy – predicts recession in US/EU
                  ( cost of capital has gone up)​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Cost-take-out Actions underway by CTOs/CIOs and CFOs​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Hybrid Workplace model – Tech + Machines ​
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Cost of the problem - Putting a $ value
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="cost of traditional service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                So, as we went through the problem and challenges, let’s also
                try to estimate the cost of the problem. For example, what’s
                costing you for not transforming to an AI-first service desk
                model?
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this case, we assume that you are a company with 3000
                employees, and roughly creating about 5000 tickets per month,
                having about 20 agents at various levels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We assume that 60% of the total ticket volume is handled at the
                L1 level, so these are simple to semi-complex issues that an L1
                staff can resolve. And rest of the tickets, due to its
                complexity, are passed on to L2 and L3 folks.
              </p>
              <BlogCta
                ContentCta="Reduce 40% Your IT Service Desk Operations Cost with Automation."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                So, coming back to L1, let’s assume that 60% of L1 tickets are
                eligible for FCR, i.e., 1800 tickets are L1 eligible, but as you
                know, not all tickets can be closed on the first call, so let’s
                assume that, on average, 80% of eligible tickets are resolved,
                so about 1400 tickets are closed on the first call. And all this
                takes about anywhere from 20 mins to 4 hrs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But let’s focus on the cost of this exercise and assume that it
                costs $15 per ticket for the company.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So as you can see, simple maths tells us that the overall cost
                for addressing 3000 tickets is 45K, and of which those 1400
                tickets cost you 21K per month, so by not having an AI chatbot
                that automatically resolves those 1400 easily, you have spent
                21K. That is one way of reducing the cost.
              </p>
              <h3 className="font-section-sub-header-small-home">
                ROI Estimates​
              </h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="RoI of AI-Powered service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Now, let’s see what would happen if we introduce a chatbot to
                help us resolve FCR tickets. With a chatbot, the average handle
                time per ticket comes down to less than a minute, and the
                average cost per ticket reduces to $3. That means the total cost
                of resolving 1440 tickets comes down to $4,320, resulting in a
                5X cost saving of $17,280 monthly and $207360 annually.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Then you can save 3546 agent hours annually.
              </p>
              <NocodeWrapper />
              <h3 className="font-section-sub-header-small-home">
                What is the ROI for your chatbot or product?​
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Hard ROI is cost savings that can be visible as early as the
                first quarter. This also depends on your user adoption. There
                are a lot of soft ROI as well that can be quantified. ​
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Hard ROI or immediate cost
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​User productivity​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workflow or service request automation​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Employee experience
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​SLAs such as Mean time to resolution (MTTR), FCR, etc​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​​Employee and Agent productivity
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The ROI for your chatbot or product is easy to quantify, and
                there are some key areas that contribute to it.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Cost savings – on average, a chatbot can automate 40-50% (or
                  more) of ticket volumes, and this can result in hard cost
                  savings from day1.
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Improve MTTR – with issues being auto-resolved by the bot
                  instantly or in seconds, your mean time to resolution (MTTR)
                  goes down by 30-40% or more.​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Improve CSAT – with instant resolution using a chat apps like
                  MS Teams or slack and employees not calling or emailing the
                  helpdesk, this can boost employee CSAT by 60-70%​​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Productivity – with hours saved back and forth from calls and
                  emails, employee productivity goes up. On average, you are
                  saving 40 mins to hours per employee per month.​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee Experience – employees are used to using WhatsApp and
                  modern payment in their personal lives, and they expect the
                  same level of experience at the workplace as well.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/blog/it-support-automation-guide">
                    Scaling your IT support Service Desk -
                  </a>{" "}
                  in the traditional model, you will need to hire, train, and
                  operate more agents to scale your service desk, but with a
                  chatbot, your scaling efforts and costs are almost nil.​
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR – can automate your mundane HR enquires and free up HR
                  teams time and efforts to focus on more meaning full
                  activities. ​
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Traditional VS Modern Service Desk
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Traditional vs Modern service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                So, let’s now see how you can{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  leverage your existing service desk team
                </a>{" "}
                and yet
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  transform it into the AI-First Service Desk Model.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                First, leverage data to train a chatbot, so it can intelligently
                and contextually respond and address to employee queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Second, whilst agents are critical to the service desk,
                re-balancing the approach with chatbot + agents creates the
                right mix of skills with cost benefits
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Third, use Automation to improve service delivery SLAs. What
                took for an account to get unlocked in 30 mins now be unlocked
                in less than a minute using a chatbot.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Imagine how this could impact your SLAs.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Fourth, proactive measures to reduce reliance on calls to the
                service desk frequently finally, you can create a rich
                experience with personalization.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Get a competitive advantage from Workativ Automation Platforms
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Workativ"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                is focused on helping companies automate workplace support and
                tasks. Established in 2019, we have come a long way, helping
                companies from different industry in transform their workplace
                using Woraktiv Assistant and Workativ Automate (Which is due for
                a release in the next two weeks)
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, Workativ assistant helps companies of all sizes to build
                conversational AI chatbots with app automation in minutes
                quickly. This helps companies adopt the AI-first service desk
                approach. We have helped popular companies like GoTo,
                Congnisante, and IPONWEB leverage chatbots to modernize employee
                IT and HR support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs mb-3">
                Real-World Case Studies
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Client - GoTo
              </h3>

              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="GoTo case study"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Here is a quick overview into a{" "}
                <a href="https://workativ.com/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                  case study
                </a>{" "}
                of our client <a href="https://www.goto.com/">GoTo</a> 一 a
                renowned and one of the leading SaaS companies with more than
                3500 employees.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                GoTo needed an automated solution that would help them manage
                all the repetitive tasks such as installing software, resetting
                passwords, unlocking accounts, and distributing lists for
                employees with more than 50+ applications. 
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                They knew there was something better out there for them, so they
                reached out to Workativ for help (and 4 other chatbot vendors)
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                Workativ ensured that a chatbot would be made available at any
                given time when an employee needed help with their IT-related
                issues.  Workativ helped the GoTo team to auto-resolve
                repetitive IT queries, issues, and requests and improve their
                employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Amidst the economic crisis, it will be inviting to invest in
                workflow automation through chatbots or sophisticated automation
                models that help save big on operating costs. For CFOs who want
                to reduce the economic strain on the IT infrastructure, chatbots
                and automation platforms can help in fulfilling that ambition by
                accelerating IT support efficiency and productivity.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The picture is clear here,
              </p>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Automation is more widely a CIO’s top priority​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Automation is more mature and available via the cloud​
                </li>
                <li className="font-section-normal-text-testimonials">
                  Customers are choosing automation at a small scale to gain
                  high impact​
                </li>
                <li className="font-section-normal-text-testimonials">
                  ​Chatbots are becoming a high priority for customers and
                  employee support
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                It would definitely bring back values to your ROI should you
                consider automation as your next level high end IT initiates.
                Look back at all these gains that automation brings to your
                balance sheet and give it a nod.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
const NocodeWrapper = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`nocode_wrapper_upd nocode_wrapper_green ${
        isMobile ? "mb-4 pl-2 pr-2" : "mb-5 mt-3 gap-2-rem"
      }`}
    >
      {isMobile ? (
        <h4 className="font-section-sub-header-small-home mb-0 text-align-center">
          AUTOMATE YOUR IT SERVICE DESK - NO CODE
        </h4>
      ) : (
        <h4 className="font-section-sub-header-small-home mb-0">
          AUTOMATE YOUR IT SERVICE DESK - NO CODE
        </h4>
      )}

      <button
        className="font-section-normal-text-testimonials-medium cursor-pointer color-white ml-30"
        type="button"
        onClick={() =>
          (window.location.href =
            "https://assistant.workativ.com/authentication/u/direct-signup")
        }
      >
        {" "}
        TRY FOR FREE
      </button>
    </div>
  );
};
const NocodeWrapperTwo = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`nocode_wrapper_upd nocode_wrapper_green ${
        isMobile ? "mb-4" : "mb-5 gap-2-rem"
      }`}
    >
      {isMobile ? (
        <h4 className="font-section-sub-header-small-home mb-0 text-align-center">
          AUTOMATE YOUR WORKPLACE SUPPORT
        </h4>
      ) : (
        <h4 className="font-section-sub-header-small-home mb-0">
          AUTOMATE YOUR WORKPLACE SUPPORT
        </h4>
      )}

      <button
        className="font-section-normal-text-testimonials-medium cursor-pointer color-white ml-30"
        type="button"
        onClick={() =>
          (window.location.href =
            "https://assistant.workativ.com/authentication/u/direct-signup")
        }
      >
        {" "}
        BOOK DEMO
      </button>
    </div>
  );
};
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk"
              className="font-section-normal-text-testimonials"
            >
              10 Surprising Benefits of AI-Powered Service Desk
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk"
              className="font-section-normal-text-testimonials"
            >
              A Complete Guide Helpdesk vs Service desk
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
